export const ENV = "dev";
export const API_HOST = "https://dev.api.admin.letsplaymusicsite.com/v2";
export const API_NAME = "dev-admin-api";
export const API_CONFIG = { endpoints: [{ name: API_NAME, endpoint: API_HOST }] };

export const COGNITO_CONFIG = {
  region: "us-east-1",
  userPoolId: "us-east-1_Agj9H4jYz",
  userPoolWebClientId: "pp84qb58mt9pt60rq9pdl24r1",
  authenticationFlowType: "USER_PASSWORD_AUTH"
};

export const GOOGLE_MAPS_API_KEY = "AIzaSyBi6W4_FsXGTs6JbPak3u3vx6zUtXsqrP4";